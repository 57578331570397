<template>
  <div class="CEESAR-PortalView">
      <slot />
  </div>
</template>

<script>
export default {
    name: "CEESAR-PortalView",
    mounted(){

      window.setTimeout( () => {
        this.$nextTick(() => this.ShowGroups())
      }, 50);
      
    },
    methods:{
      ShowGroups(){
        let slots = this.$slots.default;

        let randSlots = [];

        while(slots.length > 0)
        {
          let i = Math.floor(Math.random() * slots.length);
          randSlots.push(slots[i]);
          slots.splice(i, 1);
        }

        window.setTimeout(() => {
          this.ShowNextGroup(randSlots);
        }, 20);
      },

      ShowNextGroup(pSlots){
        let slot = pSlots[0];
        pSlots.splice(0, 1);

        slot.elm.classList.remove("CEESAR-PortalGroup-Hidden");
        
        if(pSlots.length > 0)
        {
          window.setTimeout(() => {
            this.ShowNextGroup(pSlots);
          }, 20);
        }
      }
    }
}
</script>

<style>

</style>